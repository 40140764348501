<template>
  <FormulateForm
    v-if="!qes"
    analytics-track="quickregPrompt"
    @submit="registerQes"
  >
    <div v-if="thankYou">
      <h2 role="heading" class="header" aria-level="1">
        Signed up for email offers
      </h2>
      <p>Thank you for Signing Up!</p>
      <p>
        Enjoy exploring our wines. If you ever need assistance, drop us a line
        at
        <a :href="`mailto:${customerServiceEmail}`">{{
          customerServiceEmail
        }}</a
        >.
      </p>
    </div>
    <template v-else>
      <h2 role="heading" class="header" aria-level="1">
        Sign up for email offers
      </h2>
      <FormulateInput
        type="email"
        label="Email"
        label-class="sr-only"
        placeholder="Enter your email"
        validation="bail|required|email"
        name="qesEmail"
        maxlength="255"
      />
      <FormulateInput
        type="text"
        label="ZIP Code"
        label-class="sr-only"
        name="zipcode"
        placeholder="Enter your ZIP code"
        validation="bail|required|matches:/^\d{5}(?:-\d{4})?$/"
        :validation-messages="{
          matches: 'You must input a valid US Zip Code',
        }"
      />
      <ErrorList :errors="Object.values(qesErrors)" />
      <FormulateInput type="submit" label="Sign Up" :disabled="loading" />
    </template>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex'
import { setUser } from '~/../common/utils/user'
import ErrorList from '~/../common/components/ErrorList.vue'

export default {
  name: 'QuickEmailSignUp',
  components: { ErrorList },
  data() {
    return {
      qesErrors: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['qes', 'thankYou', 'customerServiceEmail']),
  },
  methods: {
    async registerQes(data) {
      this.qesErrors = {}
      this.loading = true
      try {
        await this.$store.dispatch('registerQes', data)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'quickreg',
        })
        const result = await this.$axios.get('/api/customer/user/')
        setUser(this.$auth, result.data)
      } catch (errors) {
        this.qesErrors = errors.response.data
      }
      this.loading = false
    },
  },
}
</script>
